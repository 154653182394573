
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import BaseSidebarItem from '../base-sidebar-item.vue';
import BaseLoader from '@/components/shared/base-loader.vue';
import { authModule } from '@t/session';
import { RouteConfig } from 'node_modules/vue-router/types';
import { appTokenMgr } from '@t/employee-app-role';
import { AccountTokenClaims } from '@/entity/shared/user';

@Component({
    components: {
        BaseSidebarItem,
        BaseLoader
    }
})
export default class SidebarPanel extends Vue {
    @Prop({ required: false, default: () => false }) collapsed!: boolean;

    private projectsCollapse: boolean = true;
    private usersCollapse: boolean = true;
    // private reportsCollapse: boolean = true;
    private configsCollapse: boolean = true;
    private listsCollapse: boolean = true;
    private RHCollapse: boolean = true;

    private forceProjectsActive: boolean = false;
    private forceUsersActive: boolean = false;
    private forceReportsActive: boolean = false;
    private forceConfigsActive: boolean = false;
    private forceListsActive: boolean = false;
    private forceRHActive: boolean = false;

    get userId(): string {
        return authModule.getAccount()?.localAccountId as string;
    }

    get canAccessUserMenu(): boolean {
        const acc = authModule.getAccount();
        return acc != null && appTokenMgr.isUser(acc);
    }

    get canAccessProjects(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isRH(acc) || appTokenMgr.isStudioManager(acc) ||
         appTokenMgr.isCP(acc) || appTokenMgr.isAssociate(acc) || appTokenMgr.isDaf(acc));
    }

    get canAccessProjectInformation(): boolean {
        const acc = authModule.getAccount();
        return acc != null && !appTokenMgr.isRH(acc);
    }

    get canAccessPlanification(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isStudioManager(acc) || appTokenMgr.isCP(acc));
    }

    get canAccessSubcontracting(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isStudioManager(acc) || appTokenMgr.isCP(acc));
    }

    get canAccessProvisionalfee(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isCP(acc) || appTokenMgr.isStudioManager(acc) || appTokenMgr.isRH(acc) || 
        appTokenMgr.isAssociate(acc) || appTokenMgr.isDaf(acc));
    }

    get canAccessInvoiceList(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isDaf(acc)); 
    }

    get canAccessInvoiceSupplierList(): boolean {
        const acc = authModule.getAccount();
        return acc != null && appTokenMgr.isAdmin(acc);
    }

    get canAccessPurchaseOrderList(): boolean {
        const acc = authModule.getAccount();
        return acc != null && appTokenMgr.isAdmin(acc);
    }

    get canAccessContractList(): boolean {
        const acc = authModule.getAccount();
        return acc != null && appTokenMgr.isAdmin(acc);
    }

    get canAccessClients(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isDaf(acc) || appTokenMgr.isAssociate(acc) || appTokenMgr.isRH(acc));
    }

    get canAccessSuppliers(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isDaf(acc) || appTokenMgr.isAssociate(acc) || appTokenMgr.isRH(acc));
    }

    get canAccessEmployeeList(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isAssociate(acc) || appTokenMgr.isDaf(acc) || appTokenMgr.isRH(acc) || appTokenMgr.isStudioManager(acc) || appTokenMgr.isCP(acc));
    }

    get canAccessConfigurations(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isAssociate(acc));
    }

    get canAccessProjectManagement(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isStudioManager(acc) || appTokenMgr.isCP(acc));
    }

    get canAccessReporting(): boolean {
        const acc = authModule.getAccount();
        return acc != null && (appTokenMgr.isAdmin(acc) || appTokenMgr.isDaf(acc) || appTokenMgr.isAssociate(acc) || appTokenMgr.isRH(acc));
    }

    @Watch('$route')
    private routeChange(to: RouteConfig, from: RouteConfig) {
        this.listsCollapse = true;
        this.usersCollapse = true;
        this.projectsCollapse = true;
        this.configsCollapse = true;
        this.RHCollapse = true;
        this.setForceActive(to.name);
    }

    private resetActive() {
        this.forceProjectsActive = false;
        this.forceListsActive = false;
        this.forceUsersActive = false;
        this.forceReportsActive = false;
        this.forceConfigsActive = false;
        this.forceRHActive = false;
    }

    private dbActiveName = {
        forceRHActive: ['users', 'imputationsRecap'],
        // forceContributorsActive: [],
        forceProjectsActive: [
            'project-information',
            'invoice-pdf',
            'projectPlanification',
            'subcontracting',
            'provisionalfee',
            'projectManagements'
        ],
        forceListsActive: [
            'projects',
            'contracts',
            'invoice-list',
            'po-list',
            'clients',
            'client-information',
            'suppliers',
            'supplier-information'
        ],
        forceUsersActive: [
            'user-information',
            'leave-information',
            'leave-information-validation',
            'over-time-information',
            'over-time-information-validation',
            'realized-over-time-information',
            'realized-over-time-information-validation',
            'imputations',
            'effective-fee-information',
            'fee-pdf'
        ],
        forceReportsActive: ['reporting'],
        forceConfigsActive: ['banks', 'employeeRoles', 'agencies', 'studios', 'salesAccount']
    };

    private setForceActive(name: string | null | undefined) {
        if (!name) {
            return;
        }

        this.resetActive();

        for (const [key, value] of Object.entries(this.dbActiveName)) {
            if (value.includes(name)) {
                this[key] = true;
            } else {
                this[key] = false;
            }
        }
    }

    @Watch('collapsed')
    private collapsedChange(newVal: boolean, _oldVal: boolean) {
        if (newVal) {
            this.setForceActive(this.$route.name);
        }
    }

    private openConfigs() {
        if (this.collapsed && this.configsCollapse === false) {
            return;
        }
        this.configsCollapse = !this.configsCollapse;
        if (this.configsCollapse === false) {
            this.listsCollapse = true;
            this.RHCollapse = true;
            this.projectsCollapse = true;
            this.usersCollapse = true;
        }
    }

    private openProjects() {
        if (this.collapsed && this.projectsCollapse === false) {
            return;
        }
        this.projectsCollapse = !this.projectsCollapse;
        if (this.projectsCollapse === false) {
            this.listsCollapse = true;
            this.usersCollapse = true;
            this.RHCollapse = true;
            this.configsCollapse = true;
        }
    }

    private openUsers() {
        if (this.collapsed && this.usersCollapse === false) {
            return;
        }
        this.usersCollapse = !this.usersCollapse;
        if (this.usersCollapse === false) {
            this.listsCollapse = true;
            this.RHCollapse = true;
            this.projectsCollapse = true;
            this.configsCollapse = true;
        }
    }

    private openLists() {
        if (this.collapsed && this.listsCollapse === false) {
            return;
        }
        this.listsCollapse = !this.listsCollapse;
        if (this.listsCollapse === false) {
            this.usersCollapse = true;
            this.projectsCollapse = true;
            this.RHCollapse = true;
            this.configsCollapse = true;
        }
    }

    private openRHs() {
        if (this.collapsed && this.RHCollapse === false) {
            return;
        }
        this.RHCollapse = !this.RHCollapse;
        if (this.RHCollapse === false) {
            this.usersCollapse = true;
            this.projectsCollapse = true;
            this.listsCollapse = true;
            this.configsCollapse = true;
        }
    }

    private mounted(): void {       
        this.setForceActive(this.$route.name);
    }
}
